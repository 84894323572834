import React, { Fragment, useState } from 'react';
import Header from '../../Components/Header';
import './Index.scss';
import Input from '../../Components/Input';

//Import our array of form step fields.
import reviewFields from './reviewFields';

function SubmitReview() {
  const [inputFields, updateInputValue] = useState(reviewFields);
  const [isValidForm, setFormState] = useState(false);

  /**
   * Set a default state for our form step.
   * valid(is the current step valid?)
   * current(current reviewFields index+1)
   */
  const [stepSate, updateStepState] = useState([
    {
      valid: false,
      current: 1,
    },
  ]);

  /**
   * @description Checks that every required field within a step has a value
   * @param stepIndex  Index of the current step
   * @return {boolean}
   */
  const checkStepFields = stepIndex => {
    return inputFields[stepIndex]
      .filter(input => input.required)
      .every(val => val.value.trim().length !== 0);
  };

  /**
   * @description Handle value change for an input element
   * @param event the element Event
   * @param stepIndex Index of the current step
   * @param fieldIndex Index of the field within the current step
   */
  const handleChange = (event, stepIndex, fieldIndex) => {
    const values = [...inputFields];
    if (values[stepIndex]) {
      values[stepIndex][fieldIndex].value = event.target.value;
      updateInputValue(values);

      const formSteps = [...stepSate];
      // check if current step is valid
      formSteps[0].valid = checkStepFields(stepIndex);
      updateStepState(formSteps);
    }
  };

  /**
   * @description handle proceeding to next step
   */
  const nextStep = () => {
    const formSteps = [...stepSate];
    if (formSteps[0].current < inputFields.length) {
      formSteps[0].current = stepSate[0].current + 1;
      updateStepState(formSteps);
    }
  };

  /**
   * @description handle going back to the previous step
   */
  const previousStep = () => {
    const formSteps = [...stepSate];
    if (formSteps[0].current >= inputFields.length) {
      formSteps[0].current = stepSate[0].current - 1;
      updateStepState(formSteps);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    const formIsValid = false;
    setFormState(formIsValid);

    // Get all values for each step in Object format
    let formData = inputFields
      .map(stepFields => [
        // go through each field for every step
        ...stepFields.map(stepField => ({
          [stepField.name]: stepField.value,
        })),
      ])
      .flat(Infinity); // Flatten the returned array of objects
    formData = Object.assign({}, ...formData);
    // eslint-disable-next-line no-console
    console.log(formData);
    // submit formData
  };

  return (
    <main>
      <Fragment>
        <Header isAuthenticated showBottomBorder className="submit-review" />
        <section className="container mb-auto submit-review">
          <h1 className="display-4 text-center">Submit Review</h1>
          <div className="step-indicator mt-5 mb-5 ml-auto mr-auto d-flex align-items-center justify-content-between">
            <button
              className={`step ${stepSate[0].current === 1 ? 'current' : ''}`}
              onClick={previousStep}
              aria-label="Previous Step"
            >
              1
            </button>
            <hr />
            <button
              className={`step ${stepSate[0].current === 2 ? 'current' : ''}`}
              disabled={!stepSate[0].valid}
              onClick={nextStep}
              aria-label="Next Step"
            >
              2
            </button>
          </div>
          <div className="d-flex justify-content-center">
            <form className="auth-form mt-4 mb-5" onSubmit={handleSubmit}>
              {isValidForm && (
                <div className="text-danger">
                  <i className="fas fa-exclamation-circle" /> Incorrect
                  credentials provided
                </div>
              )}
              {inputFields.map((stepFields, stepIndex) => (
                <div
                  key={stepIndex}
                  className={`form-group step-${stepIndex + 1} ${
                    stepSate[0].current === stepIndex + 1 ? 'd-block' : 'd-none'
                  }`}
                >
                  <div className="d-flex flex-column">
                    {stepFields.map((fieldObject, fieldIndex) => {
                      switch (fieldObject.type) {
                        case 'text':
                          return (
                            <Input
                              key={fieldIndex}
                              type="text"
                              className="form-control"
                              id={fieldObject.name}
                              name={fieldObject.name}
                              label={fieldObject.label}
                              placeholder={fieldObject.label}
                              maxLength={
                                fieldObject.maxLength
                                  ? fieldObject.maxLength
                                  : null
                              }
                              onChange={ev =>
                                handleChange(ev, stepIndex, fieldIndex)
                              }
                              required={fieldObject.required}
                            >
                              {fieldObject.maxLength && (
                                <span
                                  className={`float-right ${fieldObject.value
                                    .length !== 100 || 'text-danger'}`}
                                >
                                  {`${fieldObject.value.length}/${fieldObject.maxLength}`}
                                </span>
                              )}
                            </Input>
                          );
                        case 'select':
                          return (
                            <div
                              className="select-container flex-column"
                              key={fieldIndex}
                            >
                              <select
                                name={fieldObject.name}
                                id={fieldObject.name}
                                required={fieldObject.required}
                                aria-label={fieldObject.label}
                                onChange={ev =>
                                  handleChange(ev, stepIndex, fieldIndex)
                                }
                              >
                                {fieldObject.options &&
                                  fieldObject.options.map((option, index) =>
                                    option === '' ? (
                                      <option
                                        value={option}
                                        defaultValue={option === ''}
                                        key={index}
                                      >
                                        {fieldObject.label}
                                      </option>
                                    ) : (
                                      <option value={option} key={index}>
                                        {option}
                                      </option>
                                    ),
                                  )}
                              </select>
                              {fieldObject.required && (
                                <span className="required-indicator">*</span>
                              )}
                              {fieldObject.maxLength && (
                                <span
                                  className={`float-right ${fieldObject.value
                                    .length !== 100 || 'text-danger'}`}
                                >
                                  {`${fieldObject.value.length}/${fieldObject.maxLength}`}
                                </span>
                              )}
                            </div>
                          );
                        case 'textarea':
                          return (
                            <div
                              className="textarea-container flex-column"
                              key={fieldIndex}
                            >
                              <textarea
                                name={fieldObject.name}
                                id={fieldObject.name}
                                placeholder="Yeah, the place was good and all..."
                                cols="30"
                                rows="10"
                                maxLength={fieldObject.maxLength}
                                onChange={ev =>
                                  handleChange(ev, stepIndex, fieldIndex)
                                }
                                required={fieldObject.required}
                              />
                              <label
                                className="input-label "
                                htmlFor={fieldObject.name}
                              >
                                {fieldObject.label}
                              </label>
                              {fieldObject.required && (
                                <span className="required-indicator">*</span>
                              )}
                              {fieldObject.maxLength && (
                                <span
                                  className={`float-right ${fieldObject.value
                                    .length !== 100 || 'text-danger'}`}
                                >
                                  {`${fieldObject.value.length}/${fieldObject.maxLength}`}
                                </span>
                              )}
                            </div>
                          );
                        default:
                          return null;
                      }
                    })}
                  </div>
                </div>
              ))}
              <div className="d-flex justify-content-between">
                <button
                  className={`btn btn-primary ${
                    stepSate[0].current === inputFields.length
                      ? 'd-block'
                      : 'd-none'
                  }`}
                  onClick={previousStep}
                >
                  Previous
                </button>
                <button
                  className={`btn btn-primary ml-auto ${
                    stepSate[0].current < inputFields.length
                      ? 'd-block'
                      : 'd-none'
                  }`}
                  disabled={!stepSate[0].valid}
                  onClick={nextStep}
                >
                  Next
                </button>
                <button
                  className={`btn btn-primary ml-auto ${
                    stepSate[0].current === inputFields.length
                      ? 'd-block'
                      : 'd-none'
                  }`}
                  disabled={!stepSate[0].valid}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </section>
      </Fragment>
    </main>
  );
}

export default SubmitReview;
