import React from 'react';
import { Link } from 'react-router-dom';

import FictionalCompany from '../../assets/images/fictional_company.jpg';
import StarFillIcon from '../../assets/images/star-fill.svg';
import StarLineIcon from '../../assets/images/star-line.svg';
import PlusIcon from '../../assets/images/plus.svg';

import './Index.scss';

function CompanyOverviewCard({ company }) {
  return (
    <div className="search-result-card d-flex flex-wrap mt-5">
      <img
        src={FictionalCompany}
        className="img-fluid company-logo"
        alt="Company Name"
      />
      <div className="d-flex flex-column w-sm-80 justify-content-between ml-md-4 ml-sm-0">
        <div className="company-overview">
          <Link to="#">
            <h1>{company.name}</h1>
          </Link>
          <span className="stars">
            {company.ratings &&
              [...Array(5).keys()].map(index =>
                index < company.ratings ? (
                  <img src={StarFillIcon} key={index} alt="Star Icon" />
                ) : (
                  <img src={StarLineIcon} key={index} alt="Star Icon" />
                ),
              )}
          </span>
          <span className="ml-3 rating-count">
            {parseInt(company.ratings).toFixed(1)}
          </span>
          <span className="d-block review-count">
            {company.reviews} Reviews
          </span>
        </div>
        <strong className="salary-details">
          Avg Salary: <span>₦{company.salary}</span>
        </strong>
      </div>
      <div className="ml-lg-auto ml-sm-0 justify-content-center w-sm-100">
        <button className="btn btn-primary d-flex justify-content-around align-self-center m-auto">
          <img src={PlusIcon} alt="plus icon" />
          Add review
        </button>
      </div>
    </div>
  );
}

export default CompanyOverviewCard;
