// Each number could later represent bad, good, excellent e.t.c
const ratingOption = ['', 1, 2, 3, 4, 5];
export default [
  [
    {
      name: 'caption',
      label: 'Caption',
      type: 'text',
      maxLength: 100,
      required: true,
      value: '',
    },
    {
      name: 'position',
      label: 'Position',
      type: 'text',
      required: true,
      value: '',
    },
    {
      name: 'role',
      label: 'Role',
      type: 'text',
      required: true,
      value: '',
    },
    {
      name: 'comment',
      label: 'Comment',
      type: 'textarea',
      maxLength: 1000,
      required: true,
      value: '',
    },
  ],
  [
    {
      name: 'overallRating',
      label: 'Overall Rating',
      type: 'select',
      required: true,
      value: '',
      options: ratingOption,
    },
    {
      name: 'compensationAndBenefits',
      label: 'Compensation and Benefits',
      type: 'select',
      value: '',
      options: [
        '',
        '0 - 50,000',
        '50,000 - 100,000',
        '100,000 - 150,000',
        '200,000 - 250,000',
        '300,000 and above',
      ],
    },
    {
      name: 'workAndLifeABalance',
      label: 'Work/Life Balance',
      type: 'select',
      value: '',
      options: ratingOption,
    },
    {
      name: 'interviewAndHiring',
      label: 'Interview and Hiring',
      type: 'select',
      value: '',
      options: ratingOption,
    },
    {
      name: 'cultureAndValues',
      label: 'Culture and Values',
      type: 'select',
      value: '',
      options: ratingOption,
    },
  ],
];
