import React from 'react';
import { Link } from 'react-router-dom';

// components
import Header from '../../Components/Header';
import Input from '../../Components/Input';

// styles
import './Index.scss';

// images
import ReviewsImage from '../../assets/images/reviews.svg';
import WageImage from '../../assets/images/download.svg';

class Index extends React.Component {
  render() {
    return (
      <main>
        <Header showNavigationMenu={true} />
        <section className="jumbotron jumbotron-fluid p-0 pt-2 px-3 px-md-0 pt-md-0 d-flex flex-column">
          <div className="container mb-auto mt-auto">
            <h1 className="display-4 text-center text-white lead">
              Search company reviews and ratings
            </h1>
          </div>
          <h2 className="text-center sub-title text-white mt-4">
            What company are you searching for?
          </h2>
          <form>
            <div className="form-group text-center">
              <Input
                type="email"
                className="form-control search-input"
                id="companyName"
                name="companyName"
                label="Enter the name of the company"
                labelclassname="text-white label"
                aria-describedby="nameHelp"
                placeholder="The name of the company..."
              />
            </div>
          </form>
        </section>

        <section className="about-section text-center px-3 px-md-0">
          <h2 className="title">ABOUT ANUBIS</h2>
          <p className="body">
            Anubis has tons of salary information and company reviews, – all
            posted anonymously by employees and job seekers. See what others are
            looking for on Anubis today.
          </p>
          <Link to="#" className="link">
            Write a Review
          </Link>
        </section>

        <section className="d-flex justify-content-center px-4 px-md-5 extras">
          <div className="row w-100 px-0">
            <div className="col-12 col-md-6 top px-0 d-flex flex-column">
              <h3 className="title mb-3 mb-md-5">
                Company reviews and ratings. Get the whole gist.
              </h3>
              <p className="mb-auto body">
                Search ratings and reviews of Nigerian tech companies. Get the
                inside scoop and find out what it&apos;s really like from people
                who&apos;ve actually worked there.
              </p>
            </div>
            <div className="col-12 col-md-6 px-0 d-flex top">
              <img
                src={ReviewsImage}
                alt="Reviews"
                className="ml-0 mx-auto ml-lg-auto img"
              />
            </div>
            <div className="w-100"></div>
            <div className="col-12 col-md-6 d-flex px-0 flex-column bottom">
              <h3 className="title  mb-3 mb-md-5">
                Find out what the pay is like at your favourite companies
              </h3>
              <p className="body mb-auto">
                How much do workers make in Nigeria? Choose a job title, and see
                what salary employees earn for that job in Nigeria. This salary
                data is based on reports of salaries, bonuses, wages, and hourly
                pay, submitted anonymously to Anubis----- by employees in
                Nigeria.
              </p>
            </div>
            <div className="col-12 col-md-6 px-0 d-flexbottom ">
              <img
                src={WageImage}
                alt="Wages"
                className="ml-0 ml-lg-auto img"
              />
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default Index;
