import React, { Fragment } from 'react';
import Header from '../../Components/Header';
import './Index.scss';
import Input from '../../Components/Input';
import { Link } from 'react-router-dom';

function ForgotPassword() {
  return (
    <main>
      <Fragment>
        <Header />
        <section
          className="container mt-md-5 d-flex
        flex-column align-self-center justify-content-center mt-sm-4 forgot-password auth-section"
        >
          <h1 className="display-4 text-center">Forgot Your Password?</h1>
          <p className="mt-md-4 mt-2 mb-md-4 mb-sm-2">
            Please enter your email address, we will send you an email with
            instructions for resetting your password
          </p>
          <form
            action=""
            className="auth-form d-flex flex-column w-100 align-items-center justify-content-between mt-4"
          >
            <div className="form-group">
              <Input
                type="email"
                className="form-control"
                id="email"
                name="email"
                aria-label="Email"
                placeholder="Email"
                label="Email"
              />
            </div>
            <button className="btn btn-primary">Send Recovery Email</button>
            <Link to="/login" className="link mt-md-5 mt-sm-4">
              Back to Sign in
            </Link>
          </form>
        </section>
      </Fragment>
    </main>
  );
}

export default ForgotPassword;
