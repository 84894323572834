import React from 'react';
import { Link } from 'react-router-dom';

// styles

import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer d-flex justify-content-around align-items-center">
      <Link to="#" className="link">
        Terms and conditions
      </Link>
      <p className="middle">Inspired by Frontstack and Glassdoor</p>
      <p>Copyright Anubis 2019</p>
    </footer>
  );
};

export default Footer;
