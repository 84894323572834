import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserAvatarIcon from '../../assets/images/user_avatar.svg';

function UserAvatar({ className }) {
  return (
    <div
      className={`d-flex user-avatar align-items-center justify-content-end ${className}`}
    >
      <Link to="#">
        <img src={UserAvatarIcon} alt="User Avatar" />
      </Link>
      <Link to="#" className="ml-4" href="#">
        Log Out
      </Link>
    </div>
  );
}

UserAvatar.propTypes = {
  className: PropTypes.string,
};

export default UserAvatar;
