import React from 'react';

// styles
import './Input.scss';

class Input extends React.Component {
  static defaultProps = {
    type: 'text',
  };

  state = {
    type: this.props.type,
  };

  render() {
    const { error, name, label, labelclassname } = this.props;
    const attributes = { ...this.props, children: null };
    return (
      <div className="input-container">
        <input autoComplete="new-password" {...attributes} />
        <label
          className={
            labelclassname ? `input-label ${labelclassname}` : 'input-label'
          }
          htmlFor={name}
        >
          {label}
        </label>
        {attributes.required && <span className="required-indicator">*</span>}
        {this.props.error && <span className="error-msg">{error}</span>}
        {this.props.children}
      </div>
    );
  }
}

export default Input;
