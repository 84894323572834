import React, { Fragment } from 'react';
import Header from '../../Components/Header';
import CompanyDetail from '../../Components/CompanyDetail';

import './Index.scss';

const companies = [
  {
    name: 'FICTIONAL COMPANY NAME',
    ratings: 2,
    reviews: 230,
    salary: '200,000',
  },
  {
    name: 'ANUBIS',
    ratings: 5,
    reviews: 230,
    salary: '200,000',
  },
  {
    name: 'FICTIONAL COMPANY NAME',
    ratings: 4,
    reviews: 230,
    salary: '200,000',
  },
  {
    name: 'FICTIONAL COMPANY NAME',
    ratings: 3,
    reviews: 230,
    salary: '200,000',
  },
  {
    name: 'FICTIONAL COMPANY NAME',
    ratings: 1,
    reviews: 230,
    salary: '200,000',
  },
  {
    name: 'FICTIONAL COMPANY NAME',
    ratings: 4,
    reviews: 230,
    salary: '200,000',
  },
];

function SearchResult() {
  return (
    <main>
      <Fragment>
        <Header isAuthenticated showBottomBorder />
        <div className="result-container">
          {companies &&
            companies.map((company, index) => (
              <CompanyDetail company={company} key={index} />
            ))}
        </div>
      </Fragment>
    </main>
  );
}

export default SearchResult;
