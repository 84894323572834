import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// pages
import Index from './pages/Index/Index.js';
import Login from './pages/Login/Index.js';
import Register from './pages/Register/Index.js';
import SubmitReview from './pages/SubmitReview/Index.js';
import ForgotPassword from './pages/ForgotPassword/Index.js';
import UpdatePassword from './pages/UpdatePassword/Index.js';
import SearchResult from './pages/SearchResult/Index.js';
import NotFound from './pages/Errors/404.js';

// components
import Footer from './Components/Footer';

// styles
import './App.scss';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <div className="content d-flex flex-column">
            <Index />
            <Footer />
          </div>
        </Route>
        <Route exact path="/login">
          <div className="content auth-page d-flex flex-column">
            <Login />
            <Footer />
          </div>
        </Route>
        <Route exact path="/register">
          <div className="content auth-page d-flex flex-column">
            <Register />
            <Footer />
          </div>
        </Route>
        <Route exact path="/submit-review">
          <div className="content review-page d-flex flex-column">
            <SubmitReview />
            <Footer />
          </div>
        </Route>
        <Route exact path="/forgot-password">
          <div className="content auth-page d-flex flex-column">
            <ForgotPassword />
            <Footer />
          </div>
        </Route>
        <Route exact path="/update-password">
          <div className="content auth-page d-flex flex-column">
            <UpdatePassword />
            <Footer />
          </div>
        </Route>
        <Route exact path="/search-result">
          <div className="content search-result d-flex flex-column">
            <SearchResult />
            <Footer />
          </div>
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
}
