import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// styles
import './Header.scss';

import SearchIcon from '../../assets/images/search_icon.svg';
import UserAvatar from '../UserAvatar';

const Header = ({
  showNavigationMenu,
  isAuthenticated,
  showBottomBorder,
  className,
}) => {
  return (
    <div>
      <nav
        className={`navbar navbar-expand-lg flex-sm-row navbar-light pb-1 ${
          showBottomBorder ? 'red-border-bottom' : ''
        } ${className || ''}`}
      >
        <div className="col-md-2 col-2">
          <Link className="navbar-brand" to="/">
            anubis
          </Link>
        </div>
        {isAuthenticated && (
          <Fragment>
            <div className="col-5 show-on-mobile">
              <UserAvatar />
            </div>
            <div className="col-md-8 search-wrap d-flex flex-nowrap justify-content-center">
              <div className="d-inline-block w-sm-100">
                <input
                  type="search"
                  placeholder="Company Name"
                  className="form-control search-input"
                />
                <span className="search-icon">
                  <img src={SearchIcon} alt="Search Icon" />
                </span>
              </div>
              <button className="btn search-button ml-4 d-md-inline-block btn-primary">
                Search
              </button>
            </div>
            <div className="col-md-2 hide-on-mobile">
              <UserAvatar />
            </div>
          </Fragment>
        )}
        {showNavigationMenu && (
          <Fragment>
            <div className="col-8 header-links d-flex justify-content-center">
              <div className="w-50 d-flex justify-content-around">
                <Link to="#">COMPANIES</Link>
                <Link to="#">REVIEWS</Link>
              </div>
            </div>
            <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
              <li className="nav-item active">
                <Link
                  className="nav-link d-flex justify-content-center align-items-center text-white"
                  to="/login"
                >
                  LOGIN/SIGNUP <span className="sr-only">(current)</span>
                </Link>
              </li>
            </ul>
          </Fragment>
        )}
      </nav>
    </div>
  );
};

Header.propTypes = {
  showNavigationMenu: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  showBottomBorder: PropTypes.bool,
  className: PropTypes.string,
};

export default Header;
