import React, { Fragment, useState } from 'react';
import Header from '../../Components/Header';
import './Index.scss';
import Input from '../../Components/Input';
import { Link } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isValidForm, setFormState] = useState(false);

  const handleSubmit = event => {
    event.preventDefault();
    setFormState(!email && !password);
  };

  return (
    <main>
      <Fragment>
        <Header />
        <section className="container mb-auto auth-section">
          <h1 className="display-4 text-center">Login</h1>
          <div className="d-flex justify-content-center">
            <form onSubmit={handleSubmit} className="auth-form mt-4">
              {isValidForm && (
                <div className="text-danger">
                  <i className="fas fa-exclamation-circle" /> Incorrect
                  credentials provided
                </div>
              )}
              <div className="form-group">
                <Input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  aria-label="Email"
                  placeholder="Email"
                  onChange={setEmail}
                />
                <Input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  aria-label="Password"
                  placeholder="Password"
                  onChange={setPassword}
                />
              </div>
              <Link
                to="/forgot-password"
                className="link mt-3 mb-3 d-inline-block"
              >
                Forgot Password?
              </Link>
              <div className="d-flex justify-content-between align-items-end flex-wrap">
                <div className="">
                  <span>New User? </span>
                  <Link to="/register" className="link">
                    Create an account
                  </Link>
                </div>
                <button className="btn btn-primary mt-md-3">Sign in</button>
              </div>
            </form>
          </div>
        </section>
      </Fragment>
    </main>
  );
}

export default Login;
