import React, { Fragment } from 'react';
import Header from '../../Components/Header';
import './Index.scss';
import Input from '../../Components/Input';

function ForgotPassword() {
  return (
    <main>
      <Fragment>
        <Header />
        <section
          className="container mt-md-5 d-flex
        flex-column align-self-center mt-sm-4 forgot-password auth-section"
        >
          <h1 className="display-4 text-center">Update Password</h1>
          <form
            action=""
            className="auth-form d-flex flex-column w-100 align-items-center justify-content-between mt-4"
          >
            <div className="form-group">
              <Input
                type="password"
                className="form-control"
                id="password"
                name="password"
                placeholder="Password"
                label="Password"
              />
            </div>
            <div className="form-group">
              <Input
                type="password"
                className="form-control"
                id="confirm_password"
                name="confirm_password"
                placeholder="Confirm Password"
                label="Confirm Password"
              />
            </div>
            <button className="btn btn-primary">Update Password</button>
          </form>
        </section>
      </Fragment>
    </main>
  );
}

export default ForgotPassword;
